import React from 'react';
import './textbox.css';

interface Props {
  name: string;
  url: string;
  subtitle: string;
}

export function TextBox(props: Props): JSX.Element {
  const { name, url, subtitle } = props;

  const nameMapping = {
    'MUSIK AM NACHMITTAG': 'MUSIK AM NACH-MITTAG',
    'Konzerte in Jugend- und Frauengefängnissen':
      'Konzerte in Jugend- und Frauen-gefängnissen',
  };

  const normalizedName = nameMapping[name] ?? name;

  const containerClass = name.toLowerCase().includes('archiv')
    ? 'textbox__container archive__textbox__container'
    : 'textbox__container';

  let textboxSubtitle = 'textbox__subtitle';
  let textboxContent = 'textbox__content';

  if (subtitle) {
    textboxSubtitle += subtitle.toLowerCase().includes('archiv')
      ? ' archiv__textbox__subtitle'
      : '';
    textboxContent += subtitle.toLowerCase().includes('archiv')
      ? ' archiv__textbox__content'
      : '';
  }

  return (
    <div className={containerClass}>
      <a className="textbox__link" href={url}>
        <div className="textbox__box" lang="de-DE">
          {subtitle && <p className={textboxSubtitle}>{subtitle}</p>}
          <p lang="de" className={textboxContent}>
            {normalizedName}
          </p>
        </div>
      </a>
    </div>
  );
}
