import React, { FC } from 'react';
import './project-menu.css';
import { Link, StaticQuery, useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';

function getCategoryName(pageUri: string): string {
  const uriArray = pageUri.split('/');
  return uriArray[2] || '';
}

function getArchiveUri(pageUri: string): string {
  const uriArray = pageUri.split('/');
  return `/${uriArray[1]}/${uriArray[2]}/archiv/`;
}

function getSubCategorySlug(pageUri: string): string {
  const uriArray = pageUri.split('/');
  return uriArray[3] || '';
}

function normalizeCategoryName(categoryName: string) {
  // will be of the form `([a-b](-[a-b]*))*`
  const categoryNameArray = categoryName.split('-');
  const reducer = (accumulator, currentValue) => {
    const chars = currentValue.split('');
    if (chars[0] !== undefined) {
      chars[0] = chars[0].toUpperCase();
    }
    const joined = chars.join('');
    return `${accumulator} ${joined}`;
  };
  return categoryNameArray.reduce(reducer, '');
}

function edgeInCategory(categoryName: string) {
  return (edge) => edge?.node?.uri.includes(categoryName);
}

function isPublishedVeranstaltung() {
  return (edge) =>
    edge?.node?.status === 'publish' &&
    edge?.node?.uri?.includes('veranstaltungen/') &&
    !edge?.node?.uri?.includes('plus/') &&
    (edge?.node?.uri?.match(/\//gi) || []).length > 3 &&
    edge?.node?.slug !== 'veranstaltungen';
}

function byMenuOrder() {
  return (a, b) =>
    (a?.node?.menu_order ?? 0) > (b?.node?.menu_order ?? 0) ? 1 : -1;
}

type ListLinkItemProps = {
  title: string;
  href: string;
  listStyleClass?: string;
  linkStyleClass?: string;
  children?: React.ReactNode;
};

function ListLinkItem(props: ListLinkItemProps): JSX.Element {
  const { title, href, listStyleClass, linkStyleClass, children } = props;

  return (
    <li className={listStyleClass}>
      <Link to={href} className={linkStyleClass}>
        <p className="link-list-item__title" lang="de-DE">
          {title}
        </p>
      </Link>
      {children}
    </li>
  );
}

function createArchiveLinkListItem(
  pageUri: string,
  categoryName: string,
  archiveUri: string,
  allChildPageNode: Array<{
    id: string;
    slug: string;
    status: string;
    uri: string;
    title: string;
    parentId: string;
  }>,
): JSX.Element {
  const isArchivActive = pageUri.includes('archiv');

  const now = new Date();

  return (
    <ListLinkItem
      key={`${categoryName}-archive`}
      title={'Archive'}
      href={archiveUri}
      listStyleClass="project-menu__item"
      linkStyleClass={`project-menu__link ${
        isArchivActive ? 'active-project-menu' : ''
      } `}
    >
      {isArchivActive && allChildPageNode && allChildPageNode.length > 0 && (
        <ul className="project-submenu">
          {allChildPageNode.map((childPage) => {
            return (
              <ListLinkItem
                key={childPage.id}
                title={childPage.title}
                href={childPage.uri}
                listStyleClass="project-submenu__item"
                linkStyleClass={`project-submenu__link ${
                  pageUri === childPage.uri ? 'active-project-menu' : ''
                } `}
              />
            );
          })}
        </ul>
      )}
    </ListLinkItem>
  );
}

/**
 * Build html elements for all the menue items and their children,
 * the element belonging to the active page will be bolder and a bit large
 * in comparison to the others.
 * @param edge
 * @param currentPageId
 * @param parentPageId
 * @param allChildPageNode
 */
function createLinkListItem(
  edge,
  currentPageId: number,
  parentPageId: number,
  allChildPageNode: Array<{
    id: string;
    slug: string;
    status: string;
    path: string;
    title: string;
    parentId: number;
  }>,
): JSX.Element {
  const isActive = currentPageId === edge.node.id;
  return (
    <ListLinkItem
      key={edge.node.id}
      title={edge.node.title}
      href={edge.node.uri}
      listStyleClass="project-menu__item"
      linkStyleClass={`project-menu__link ${
        isActive ? 'active-project-menu' : ''
      } `}
    />
  );
}

export interface VeranstaltungMenuProps {
  pageId: number;
  parentPageId: number;
  pageUri: string;
}

/**
 * Build side menu entries for all `Projekte` pages and their children
 * @param props
 * @constructor
 */
const VeranstaltungMenu: FC<VeranstaltungMenuProps> = ({
  pageId,
  parentPageId,
  pageUri,
}) => {
  const categoryName = getCategoryName(pageUri);
  const archiveUri = getArchiveUri(pageUri);
  const data = useStaticQuery(graphql`
    query {
      allWpPage(filter: { uri: { regex: "/veranstaltungen/(.+)/" } }) {
        edges {
          node {
            id
            status
            uri
            title
            slug
            menuOrder
            parentId
          }
        }
      }
    }
  `);
  // console.log('veranstaltung menu', { data, categoryName });
  const sortedPages = data.allWpPage.edges
    .filter(isPublishedVeranstaltung())
    .filter(edgeInCategory(categoryName))
    .sort(byMenuOrder());
  // console.log('veranstaltung sorted', sortedPages);
  return (
    categoryName !== '' && (
      <div className="project-menu__container">
        <ul className="project-menu">
          {sortedPages.map((edge, index) => {
            if (!edge.node.uri.toLowerCase().includes('archiv')) {
              return createLinkListItem(edge, pageId, parentPageId, []);
            }
          })}
          {createArchiveLinkListItem(pageUri, categoryName, archiveUri, [])}
        </ul>
      </div>
    )
  );
};

export default VeranstaltungMenu;
