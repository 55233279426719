import React, { useState } from 'react';
import { graphql } from 'gatsby';
import BasicStructure from '../../components/basic-structure/basic-structure';
import ContentContainer from '../../components/content-container/content-container';
import Sidebar from '../../components/sidebar/sidebar';
import VeranstaltungMenu from '../../components/side-bar-components/veranstaltung-menu';
import TitleMetaTags from '../../components/title-meta-tags/title-meta-tags';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Menubar from '../../components/menu/menubar';
import BurgerMenu from '../../components/burgermenu/burgermenu';
import { TextBox } from '../../components/textbox/textbox';
import { getWpBlock } from '../../components/wp-blocks';
import './veranstaltung.css';
import ScrollUp from '../../components/scroll-up/scroll-up';

/**
 * Simple component rendering the Veranstaltung with Calender pages
 */

interface Props {
  wpPage: any;
  allWpPost: any;
  allAhKlavierSheetsData: any;
  allMaNGrossSheetsData: any;
  allSchulkonzerteSheetsData: any;
}

interface Event {
  startDate: Date;
  title: string;
  venue;
  category: string;
}

interface SheetData {
  datum: string;
  time: string;
  title: string;
  ort: string;
}

interface SheetDataAhKlavier {
  datum: string;
  uhrzeit: string;
  heim: string;
  ort: string;
}

interface SheetDataMaNGross {
  datum: string;
  uhrzeit: string;
  saal: string;
  ort: string;
}

interface SheetDataSchulkonzerte {
  datum: string;
  uhrzeit: string;
  schule: string;
  ort: string;
}

function groupEventsByMonth(events: Event[]): Record<string, Event[]> {
  const eventsByMonth = {};
  events.forEach((event: Event) => {
    const month = event.startDate.toLocaleString('de', { month: 'long' });
    if (eventsByMonth.hasOwnProperty(month)) {
      eventsByMonth[month] = [event, ...eventsByMonth[month]];
    } else {
      eventsByMonth[month] = [event];
    }
  });
  return eventsByMonth;
}

function sortEventsByMonth(events: Record<string, Event[]>) : Record<string, Event[]> {
  const monthMap: Record<string, number> = {
    'Januar': 0,
    'Februar': 1,
    'März': 2,
    'April': 3,
    'Mai': 4,
    'Juni': 5,
    'Juli': 6,
    'August': 7,
    'September': 8,
    'Oktober': 9,
    'November': 10,
    'Dezember': 11
  };

  const sortedObjectsFromRecord = Object.keys(events).sort((a, b) => {
    const monthNumberA = monthMap[a];
    const monthNumberB = monthMap[b];
    return monthNumberA - monthNumberB;
  });

  const eventsSortedByMonth: Record<string, Event[]> = {};
  sortedObjectsFromRecord.forEach(key => {
    eventsSortedByMonth[key] = events[key];
  });

  return eventsSortedByMonth;
}

function orderByDate(event: Event, other: Event): number {
  if (event.startDate < other.startDate) {
    return -1;
  } else if (event.startDate > other.startDate) {
    return 1;
  } else {
    return 0;
  }
}

function mapGraphqlToEvent(sheetData: SheetData[], category: string): Event[] {
  return sheetData.map((data) => {
    const dateRegEx = /(\d{2})\.(\d{2})\.(\d{4})/;
    const dateMatched = data?.datum?.match(dateRegEx);
    const day = dateMatched == null ? '01' : dateMatched[1];
    const month = dateMatched == null ? '01' : dateMatched[2];
    const year = dateMatched == null ? '0000' : dateMatched[3];

    const timeRegEx = /(\d{2})\.(\d{2})/;
    const timeMatched = data?.time?.match(timeRegEx);
    const hours = timeMatched == null ? '00' : timeMatched[1];
    const minutes = timeMatched == null ? '00' : timeMatched[2];

    const title = data?.title == null ? '' : data.title;

    const ortRegex = /(\d+ |.{0})(\D*)/; // match 'PLZ Venue' oder 'Venue' putting Venue in the 2nd capture group
    const ortMatched = data?.ort?.match(ortRegex);
    const ort = ortMatched == null ? data.ort : ortMatched[2];

    return {
      startDate: new Date(
        year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':00',
      ),
      title: title,
      venue: ort,
      category: category,
    };
  });
}
function doubleDigitPrefix(digit: number): string {
  const string = digit.toString();
  return string.length > 1 ? string : `0${string}`;
}

function doubleDigitPostfix(digit: number): string {
  const string = digit.toString();
  return string.length > 1 ? string : `${string}0`;
}

function buildEvent(event: Event): JSX.Element {
  const startDate = event.startDate;
  const day = startDate.getDate();
  const month = startDate.getMonth() + 1;
  // here we check whether the event has so duration,
  // if not we assume that the time frame is still to be
  // disclosed. Ultimately we not display the event's time
  // in the UI.
  const hours = startDate.getHours();
  const minutes = startDate.getMinutes();
  const time =
    hours !== 0 || minutes !== 0
      ? `${doubleDigitPrefix(hours)}:${doubleDigitPostfix(minutes)} Uhr`
      : '';

  const venue = event.venue ? event.venue : '';

  return (
    <div
      className="event"
      key={`${event.startDate}-entry-${startDate.toISOString()}`}
    >
      <p className="event-date">
        {doubleDigitPrefix(day)}.{doubleDigitPrefix(month)}
      </p>
      <p className="event-time">{time}</p>
      <p
        className="event-title"
        dangerouslySetInnerHTML={{ __html: event.title }}
      />
      <p className="event-venue" dangerouslySetInnerHTML={{ __html: venue }} />
    </div>
  );
}

function eventInInterval(allEvent: Event[], start: Date, end: Date) {
  return allEvent.filter((event: Event) => {
    const date = event.startDate;
    return start < date && date < end;
  });
}

function Veranstaltung(props: { data: Props; pageContext }): JSX.Element {
  const date = new Date(props.pageContext.startOfYear);
  const endDate = new Date(props.pageContext.endOfYear);
  const year = date.getFullYear();

  const page = props.data.wpPage;
  const parentTitle = props.pageContext.parentTitle;
  const grandParentTitle = props.pageContext.grandParentTitle;
  let pageTitle = page?.title || '';
  let uri = page.uri || '';
  // if the page is an archive we have to transform it
  if (uri.includes('archiv')) {
    const urlSplit = page.uri.split('/');
    const indexString = urlSplit[urlSplit.length - 2];
    const index =
      indexString === 'vorletztesjahr'
        ? 2
        : indexString === 'letztesjahr'
        ? 1
        : 0;
    uri = `${urlSplit.slice(0, urlSplit.length - 2).join('/')}/${year}/`;
    pageTitle = `Archiv ${year} ${parentTitle}`;
  }

  const blocks = page?.blocks || [];

  const reducer = (acc: any[], edge) => {
    if (edge.node !== null) {
      return acc.concat(edge.node);
    } else {
      return acc;
    }
  };

  let sheetData: SheetData[] = [];
  let category: string = '';

  switch (props.pageContext.slug) {
    case 'konzerte-in-altenheimen': {
      sheetData = props.data.allAhKlavierSheetsData.edges
        .reduce(reducer, [])
        .map((data: SheetDataAhKlavier) => {
          return {
            datum: data.datum,
            time: data.uhrzeit,
            title: data.heim,
            ort: data.ort,
          };
        });
      category = 'konzerte-in-altenheimen';
      break;
    }
    case 'oeffentliche-konzerte-in-festlichen-saelen': {
      sheetData = props.data.allMaNGrossSheetsData.edges
        .reduce(reducer, [])
        .map((data: SheetDataMaNGross) => {
          return {
            datum: data.datum,
            time: data.uhrzeit,
            title: data.saal,
            ort: data.ort,
          };
        });

      category = 'oeffentliche-konzerte-in-festlichen-saelen';
      break;
    }
    case 'schulkonzerte': {
      sheetData = props.data.allSchulkonzerteSheetsData.edges
        .reduce(reducer, [])
        .map((data: SheetDataSchulkonzerte) => {
          return {
            datum: data.datum,
            time: '00.00',
            title: data.schule,
            ort: data.ort,
          };
        });
      category = 'schulkonzerte';
      break;
    }
  }

  let events: Event[] = [];

  if (sheetData.length > 0) {
    events = mapGraphqlToEvent(sheetData, category);
  }

  const eventsInInterval: Event[] = eventInInterval(events, date, endDate);

  const emptyQuery = '';
  const emptySet: Event[] = [];

  const [state, setState] = useState({
    filteredData: emptySet,
    query: emptyQuery,
  });

  const handleInputChange = (event) => {
    console.log(events);
    const query = event.target.value;

    let filteredData: Event[] = [];
    if (query !== null) {
      filteredData = eventsInInterval.filter((event: Event) => {
        const date = event.startDate;
        const dayMonth = `${doubleDigitPrefix(
          date.getDate(),
        )}.${doubleDigitPrefix(date.getMonth() + 1)}`;

        return (
          event.title.toLowerCase().includes(query.toLowerCase()) ||
          dayMonth === query.toLowerCase() ||
          event.venue?.toLowerCase().includes(query.toLowerCase())
        );
      });
    }

    setState({
      query,
      filteredData,
    });
  };

  const { filteredData, query } = state;

  const hasSearchResults = filteredData && query !== emptyQuery;

  const allEvent = hasSearchResults ? filteredData : eventsInInterval;

  const allEventsByMonth: Record<string, Event[]> =
    groupEventsByMonth(allEvent);

  const eventsByMonth: Record<string, Event[]> = sortEventsByMonth(allEventsByMonth);

  return (
    <BasicStructure>
      <TitleMetaTags title={pageTitle} />
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu>
          <VeranstaltungMenu
            pageId={page.id}
            parentPageId={page.parentId}
            pageUri={uri}
          />
        </BurgerMenu>
      </Header>
      <Sidebar>
        <VeranstaltungMenu
          pageId={page.id}
          parentPageId={page.parentId}
          pageUri={uri}
        />
        <ScrollUp />
      </Sidebar>
      <ContentContainer>
        <section className="veranstaltung-page">
          {uri.includes('archiv') ? (
            <div>
              <h1 className="category-title">{grandParentTitle}</h1>
              <h2 className="subcategory-title heading-square--full">
                {parentTitle}
              </h2>
            </div>
          ) : (
            <div>
              <h1 className="category-title">{parentTitle}</h1>
              <h2 className="subcategory-title heading-square--full">
                {pageTitle}
              </h2>
            </div>
          )}
          <div className="search-container">
            <div className="search-box">
              <input
                className="search-input"
                type="text"
                aria-label="Search"
                placeholder="Suche:"
                onChange={handleInputChange}
              />
            </div>
            <div className="search-info">
              <p>
                Änderungen der Termine möglich. <br /> Anfangszeiten: Tel.
                <a href="tel:+4908954041180">+49 (0) 89 54 04 11 8 - 0</a>
              </p>
            </div>
          </div>
          {uri.includes('archiv') && (
            <h2 className="archiv-title">Archiv {year}</h2>
          )}
          {uri.includes('konzerte-in-altenheimen') && (
            <div className="altenheime-coronahinweis">
              <p>
                Trotz Pandemie und ursprünglicher Absagen konnten wir in den
                beiden vergangenen Jahren, Dank größtmöglichem Engagement,
                letztlich sage und schreibe je über 900 Konzerte realisieren –
                zur riesigen Freude zahlloser älterer Menschen.{' '}
              </p>
            </div>
          )}
          <div className="multicolumn-container">
            <div className="column-first">
              {Object.keys(eventsByMonth).map((month) => {
                return (
                  <div key={`${pageTitle}-${month}-events`}>
                    <h3>
                      {month} {year}:
                    </h3>
                    {eventsByMonth[month]
                      .sort(orderByDate)
                      .map((event: Event) => buildEvent(event))}
                  </div>
                );
              })}
            </div>
            <div className="column-second">
              {props.data.allWpPost.edges.length > 0 && (
                <div
                  className="veranstaltungsuebersicht__box programm__hefte"
                  key={`${pageTitle}-programmbeispiele`}
                >
                  <TextBox
                    url={`${uri}programmbeispiele/`}
                    name={`Programmbeispiele ${year}`}
                  />
                </div>
              )}
              {blocks.length > 0 &&
                blocks.map((block, index) => {
                  const WpBlock = getWpBlock(block.__typename);

                  if (!WpBlock) return null;

                  const blockKey = `page-${page.id}-block-${index}`;

                  return (
                    <div className="veranstaltung__image" key={blockKey}>
                      <WpBlock block={block} />
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}

export const pageQuery = graphql`
  query ($pageId: String!, $slug: String!, $startdate: Date!, $enddate: Date!) {
    allAhKlavierSheetsData {
      edges {
        node {
          datum
          uhrzeit
          heim
          ort
        }
      }
    }
    allMaNGrossSheetsData {
      edges {
        node {
          datum
          uhrzeit
          saal
          ort
        }
      }
    }
    allSchulkonzerteSheetsData {
      edges {
        node {
          datum
          uhrzeit
          schule
          ort
        }
      }
    }
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $slug } } } }
        date: { gt: $startdate, lt: $enddate }
      }
    ) {
      edges {
        node {
          id
        }
      }
    }
    wpPage(id: { eq: $pageId }) {
      id
      parentId
      title
      uri
      databaseId
      parentDatabaseId
      blocks {
        __typename
        dynamicContent
        isDynamic
        originalContent
        saveContent
        ...WpCoreImageBlockFragment
        ...WpCoreGalleryBlockFragment
        innerBlocks {
          ...WpCoreImageBlockForGalleryFragment
        }
      }
    }
  }
`;

export default Veranstaltung;
